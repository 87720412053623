import request from '@/../utils/request'
const api_name = '/iotechserver/homePage'

export default {
    wirelessSmokeDetector(deviceName) {
        return request({
            url: `${api_name}/wirelessSmokeDetector/${deviceName}`,
            method: 'get',
        })
    },

    dynamicQuery(deviceId) {
        return request({
            url: `${api_name}/dynamicQuery/${deviceId}`,
            method: 'get',
        })
    },

    viewVideo(deviceId) {
        return request({
            url: `${api_name}/viewVideo/${deviceId}`,
            method: 'get',
        })
    },

    // getAllDeviceInfoStatic(deviceName,deviceName1,deviceName2) {
    //     return request({
    //         url: `${api_name}/getAllDeviceInfoStatic/${deviceName}${deviceName1}${deviceName2}`,
    //         method: 'get',
    //     })
    // },

    getAllDeviceInfoStatic(deviceName) {
        return request({
            url: `${api_name}/getAllDeviceInfoStatic`,
            method: 'post',
            data: deviceName
        })
    },

    //查询报警的设备
    getAllAlarmDevice() {
        return request({
            url: `${api_name}/getAllAlarmDevice`,
            method: 'get',
        })
    },
    //查询失联的设备
    getAlllostInDevice() {
        return request({
            url: `${api_name}/getAllLostInDevice`,
            method: 'get',
        })
    },
    //查询故障的设备
    getAllfaultDevice() {
        return request({
            url: `${api_name}/getAllfaultDevice`,
            method: 'get',
        })
    },
    // 报警设备一键处理
    toDealWithAll(redisKey) {
        return request({
            url: `${api_name}/toDealWithAll/${redisKey}`,
            method: 'get',
        })
    },

    // 处理单个设备
    toDealWith(deviceInfoHomePageVo) {
        return request({
            url: `${api_name}/toDealWith`,
            method: 'post',
            data: deviceInfoHomePageVo
        })
    },

    // 处理单个设备
    getAllHistory() {
        return request({
            url: `${api_name}/getAllHistory`,
            method: 'get',
        })
    },

    //查询设备绑定视屏的url
    getVideoUrl(deviceId) {
        return request({
            url: `${api_name}/getVideoUrl/${deviceId}`,
            method: 'get',
        })
    },




}