import request from '../../../utils/request'
const api_name = '/iotechserver/deviceCacheValue'

export default {

    getDeviceCachValue() {
        return request({
            url: `${api_name}/getDeviceCachValue`,
            method: 'post'
        })
    },

    getSmokeAboatInfo() {
        return request({
            url: `${api_name}/getSmokeAboatInfo`,
            method: 'get'
        })
    },
    getDevicenumber(){
        return request({
            url: `${api_name}/getDevicenumber`,
            method: 'get'
        })
    }



}