<template>
  <div id="app">
    <el-dialog
      title="失联设备信息"
      :visible.sync="visible"
      :close-on-click-modal="false"
      @close="dialogClose"
      style="cursor: pointer"
    >
      <dv-decoration-6 class="zhuangshi" />

      <el-button type="primary" class="process" @click="process"
        >一键处理</el-button
      >

      <div class="container">
        <dv-border-box-7 class="content">
          <div class="content-item">
            <span>设备号</span>
            <span>设备类型</span>
            <span>绑定公司</span>
            <span>失联状态</span>
            <span>创建时间</span>
            <span>操作</span>
          </div>
          <dv-decoration-4 :reverse="true" style="width: 100%; height: 10px" />

          <div class="border-box">
            <div class="content-item1" v-for="item in itemList">
              <div class="box-item" :title="item.id">
                <el-button
                  title="点击复制"
                  type="info"
                  size="mini"
                  class="el-icon-document-copy tag-read"
                  circle
                  :data-clipboard-text="item.id"
                  @click="copyInfo(item.id)"
                ></el-button>
                <span>&nbsp {{ item.id }}</span>
              </div>
              <div class="box-item" :title="item.type">{{ item.type }}</div>
              <div class="box-item" :title="item.company">
                {{ item.company }}
              </div>
              <div class="box-item" :title="item.attribute">
                {{ item.attribute }}
              </div>
              <div class="box-item" :title="item.time">
                {{ item.createdTime }}
              </div>
              <div class="box-item">
                <el-button
                  type="primary"
                  size="small"
                  @click="checkDetails(item.id, item.statusId, item.attribute)"
                  >查看详情</el-button
                >
              </div>
            </div>
          </div>
        </dv-border-box-7>

        <!-- 查看详情 -->
        <el-dialog
          title="设备详细信息"
          :visible.sync="showDetails"
          width="50%"
          class="check-details"
        >
          <dv-decoration-6 class="zhuangshi" />

          <div style="padding: 0 10px">
            <el-button
              size="small"
              style="font-size: 15px"
              type="info"
              round
              @click="video"
            >
              &nbsp;视 频&nbsp;
            </el-button>
            <el-button
              size="small"
              style="font-size: 15px"
              type="info"
              round
              @click="toProcess"
            >
              &nbsp;处 理&nbsp;
            </el-button>
            <el-button
              size="small"
              style="font-size: 15px"
              type="info"
              round
              @click="toCRT"
            >
              &nbsp;CRT&nbsp;
            </el-button>
          </div>

          <div class="show-detils">
            <dv-border-box-7
              class="detils"
              v-for="(item, index) in detilesList"
            >
              <div style="width: 100%" class="box">
                <div :title="item.name">名称：{{ item.name }}</div>
                <div :title="'状态值：' + item.status">
                  状态 / 值：{{ item.status }}
                </div>
              </div>
            </dv-border-box-7>
          </div>
        </el-dialog>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import HomePage from "@/api/manageApi/HomePage";
import deviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";
import crtDeviceCoordinate from "@/api/manageApi/CrtDeviceCoordinate";
export default {
  showDetails: false,
  name: "Wxyg",
  props: {
    dialogFlag: {
      default: false,
    },
    showDetails: {
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      overviews: false,

      redisKey: "lostInDevice",

      detilesList: [],

      itemList: [],

      deviceInfoHomePageVo: {},
      // 烟感
      deviceInfoQuery: {
        id: "DXHMSmoke",
        typeName: "电信无线烟感",
      },

      // 获取的设备类型信息
      deviceInfo: [],

      // 设备详细信息
      deviceValueInfoList: [],

      // deviceName: "无线烟感",
      deviceName: "户传",
    };
  },
  mounted() {},
  created() {
    this.getWxygInfo();

    // setInterval(() => {
    //   this.getWxygInfo();
    // }, 10000);
  },
  watch: {
    dialogFlag() {
      this.visible = this.dialogFlag;
    },
  },
  methods: {
    dialogClose() {
      this.$emit("update:dialogFlag", false);
    },

    // 一键处理 报警
    process() {
      HomePage.toDealWithAll(this.redisKey).then((res) => {
        if (res.data.message == "处理成功") {
          // 弹一个处理成功的窗
          this.$message.success(res.data.message);
          this.getWxygInfo();
          this.showDetails = false;
          this.dialogClose();
        }
      });
    },

    // 单个处理
    toProcess() {
      HomePage.toDealWith(this.deviceInfoHomePageVo).then((res) => {
        if (res.data.message == "处理成功") {
          this.$message.success(res.data.message);
          // 弹一个处理成功的窗
          this.getWxygInfo();
          this.deviceInfoHomePageVo = {};
          this.showDetails = false;
        }
      });
    },

    // 视频
    video() {
      HomePage.getVideoUrl(this.deviceInfoHomePageVo.id).then((res) => {
        if (res.data.data.VideoUrl != null && res.data.data.VideoUrl != "") {
          window.location.href = res.data.data.VideoUrl;
        } else {
          this.$message({
            type: "error",
            message: "该设备未绑定任何摄像头！",
          });
        }
      });
    },

    // 查看CRT
    toCRT() {
      crtDeviceCoordinate
        .getCoordinateByDeviceId(this.deviceInfoHomePageVo.id)
        .then((res) => {
          if (res.data.message != "失败") {
            this.$router.push({
              name: "CRTShow",
              params: {
                CRTId: res.data.data.data.crtPictureId,
                deviceId: res.data.data.data.deviceInfoId,
              },
            });
          } else {
            this.$message({
              type: "warning",
              message: "该设备尚未添加点位！",
            });
          }
        });
    },

    getWxygInfo() {
      HomePage.getAlllostInDevice().then((res) => {
        if (res.data.data.lostInDevice != null) {
          this.itemList = res.data.data.lostInDevice;
        }
      });
    },

    // 查看详情按钮
    checkDetails(id, statusId, attribute) {
      this.deviceInfoHomePageVo = {};
      this.deviceInfoHomePageVo.id = id;
      this.deviceInfoHomePageVo.statusId = statusId;
      this.deviceInfoHomePageVo.attribute = attribute;
      this.deviceInfoHomePageVo.redisKey = "lostInDevice";
      this.showDetails = true;
      HomePage.dynamicQuery(id).then((res) => {
        this.detilesList = res.data.data.detilesList;
      });
    },

    // 查看CRT按钮
    checkCrt() {
      this.$router.push("/CRTShow");
    },

    // 设备总览
    overView() {
      this.overviews = true;
    },

    // 点击复制设备ID
    copyInfo() {
      var clipboard = new Clipboard(".tag-read");
      clipboard.on("success", (e) => {
        this.$message.success("复制成功！");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.$message.error("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog {
  background: rgba(0, 0, 0, 0.5) !important;
  background-image: url(../../assets/images/panel.png) !important;
  background-size: 100% 100% !important;
  width: 50vw;
  height: 55vh;
  border-radius: 15px;
  padding: 10px 0 0 10px;
  text-shadow: 4px 4px 4px rgb(0, 0, 0) !important;

  position: relative;
}

/deep/.el-dialog__title {
  color: #81f8f2;
  font-size: 26px;
}

.container {
  display: flex;
  color: #81f8f2;
  font-size: 18px;
  text-shadow: none;
}

.zhuangshi {
  width: 180px;
  height: 15px;
  position: absolute;
  top: 70px;
}

.content {
  width: 100%;
  height: 40vh;
  cursor: pointer;
  padding: 20px 0;
  box-shadow: 0px 0px 20px #062b6c !important;

  .content-item {
    padding: 0px 0 5px 0;
    font-size: 22px;
    font-family: "宋体";

    span {
      width: 16.66%;
      display: inline-block;
      text-align: center;
      font-family: "宋体";
    }
  }

  .border-box {
    height: 90% !important;
    overflow: auto;

    .content-item1 {
      display: flex;

      .box-item {
        width: 16.66%;
        text-align: center;
        line-height: 50px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

::-webkit-scrollbar {
  display: none;
}

.check-details {
  background: rgba(14, 13, 13, 0.8);
}

/deep/.el-dialog__headerbtn .el-dialog__close {
  font-size: 25px;
  color: #fff;
  margin: 10px;
}

.show-detils {
  display: flex;
  flex-wrap: wrap;
  height: 40vh;
  overflow-y: scroll;

  .detils {
    padding: 10px;
    width: 13vw;
    height: 15vh;
    margin: 25px 10px;
    line-height: 40px;
    padding-left: 20px;
    font-size: 18px;
    color: skyblue;
    animation: breathing 1s linear infinite alternate;

    div {
      display: block;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .box::before {
      content: "属性值：";
      color: rgb(176, 189, 187);
      font-size: 20px;
    }
  }
}

@keyframes breathing {
  0% {
    transform: scale(0.97);
  }

  100% {
    transform: scale(1);
  }
}

.process {
  position: absolute;
  top: 5%;
  left: 25%;
  font-size: 16px;
}
</style>
