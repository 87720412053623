<template>
  <div style="margin-top: 20px; padding: 0px 15px">
    <div class="slide-title1" style="padding: 0px 5px">
      <span>设备名称</span>
      <span>报警类型</span>
      <span>处理状态</span></span>
    </div>
    <vue-seamless-scroll :data="listData" class="seamless-warp">
      <ul class="item">
        <li v-for="item in listData">
          <span class="dname" v-text="item.typeName"></span>
          <span class="daddress" v-text="item.value"></span>
          <span class="dnum" v-text="item.handleStatus"></span>
        </li>
      </ul>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import HomePage from "@/api/manageApi/HomePage";

export default {
  data() {
    return {
      listData: [],
    };
  },
  created() {
    //先执行一次，之后每隔十秒获取一次
    HomePage.getAllHistory().then((resp) => {
      if (resp) {
        this.listData = resp.data.data.list;
      }
    });
    setInterval(() => {
      setTimeout(() => {
        ///调取接口
        this.init();
      }, 0);
    }, 10000);
  },
  destroyed() {
    this.init();
  },
  methods: {
    init() {
      HomePage.getAllHistory().then((resp) => {
        if (resp) {
          this.listData = resp.data.data.list;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-title1 {
  width: 100%;
  font-weight: bold;
  // color: #fff;
  line-height: 15px;
  display: flex;
  justify-content: space-around;
}
.slide-title1 span {
  width: 33.33333%;
  display: inline-block;
  text-align: center;
  letter-spacing: 2px;
  color: #7fffd4;
  font-size: 18px;
  padding-bottom: 20px;
}
.seamless-warp {
  overflow: hidden;
  .item{
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    li span{
      display: inline-block;
      width: 33.33%;
      margin: 10px 0;
    }
  }
}
</style>
