<template>
  <div id="app">
    <el-dialog
      title="火灾报警设备"
      :visible.sync="visible"
      :close-on-click-modal="false"
      @close="dialogClose"
    >
      <dv-decoration-6 class="zhuangshi" />

      <div class="container">
        <dv-border-box-7 class="content">
          <div class="content-item">
            <span>设备ID</span>
            <span>设备类型</span>
            <span>安装位置</span>
            <span>所属系统</span>
            <span>绑定公司</span>
            <span>CRT信息</span>
            <span>设备信息</span>
          </div>
          <dv-decoration-4 :reverse="true" style="width: 100%; height: 10px" />

          <div class="border-box">
            <div class="content-item1" v-for="item in itemList">
              <div class="box-item" :title="item.id">
                <el-button
                  title="点击复制"
                  type="info"
                  size="mini"
                  class="el-icon-document-copy tag-read"
                  circle
                  :data-clipboard-text="item.id"
                  @click="copyInfo(item.id)"
                ></el-button>
                <span>&nbsp; {{ item.id }}</span>
              </div>
              <div class="box-item" :title="item.type">{{ item.type }}</div>
              <div class="box-item" :title="item.address">
                {{ item.address }}
              </div>
              <div class="box-item" :title="item.system">{{ item.system }}</div>
              <div class="box-item" :title="item.company">
                {{ item.company }}
              </div>
              <div class="box-item">
                <el-button
                  type="success"
                  size="small"
                  @click="checkCrt(item.id)"
                  >查看CRT</el-button
                >
              </div>
              <div class="box-item">
                <el-button
                  type="primary"
                  size="small"
                  @click="checkDetails(item.id)"
                  >查看详情</el-button
                >
              </div>
            </div>
          </div>
        </dv-border-box-7>

        <!-- 查看详情 -->
        <el-dialog
          title="设备详细信息"
          :visible.sync="showDetails"
          width="50%"
          class="check-details"
        >
          <dv-decoration-6 class="zhuangshi" />
          <div class="show-detils">
            <dv-border-box-7
              class="detils"
              v-for="(item, index) in detilesList"
            >
              <div style="width: 100%" class="box">
                <div :title="item.name">名称：{{ item.name }}</div>
                <div :title="'状态值：' + item.status">
                  状态 / 值：{{ item.status }}
                </div>
              </div>
            </dv-border-box-7>
          </div>
        </el-dialog>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import HomePage from "@/api/manageApi/HomePage";
import deviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";
import crtDeviceCoordinate from "@/api/manageApi/CrtDeviceCoordinate";

import noticeSysManagement from "@/api/managementApi/NoticeSysManagement";

export default {
  showDetails: false,
  name: "Hzbj",
  props: {
    dialogFlag: {
      default: false,
    },
    showDetails: {
      default: false,
    },
  },
  data() {
    return {
      systemNames: [],

      detilesList: [],
      itemList: [],

      visible: false,

      // 获取的设备类型信息
      deviceInfo: [],

      // 设备详细信息
      deviceValueInfoList: [],

      // deviceName: "无线烟感",
      deviceName: "用户传输装置",
    };
  },
  created() {
    // this.getDeviceInfo();
    this.getWxygInfo();
    this.jurisSysName();
  },
  watch: {
    dialogFlag() {
      this.visible = this.dialogFlag;
    },
  },

  methods: {
    // 所属系统
    jurisSysName() {
      noticeSysManagement.getNotifyBigSystemById().then((res) => {
        this.systemNames = res.data.data.list;
        console.log(this.systemNames);
      });
    },

    dialogClose() {
      this.$emit("update:dialogFlag", false);
    },

    getWxygInfo() {
      HomePage.wirelessSmokeDetector(this.deviceName).then((res) => {
        this.itemList = res.data.data.itemList;
          for(let i = 0; i < this.itemList.length; i++){
            for(let j = 0; j < this.systemNames.length; j++){
              if(this.itemList[i].system == this.systemNames[j].id){
                this.itemList[i].system = this.systemNames[j].bigSystemName
              }
            }
          }
      });
    },

    // 查看详情按钮
    checkDetails(id) {
      this.showDetails = true;
      HomePage.dynamicQuery(id).then((res) => {
        this.detilesList = res.data.data.detilesList;
      });
    },

    // 查看CRT按钮
    checkCrt(id) {
      crtDeviceCoordinate.getCoordinateByDeviceId(id).then((res) => {
        if (res.data.message != "失败") {
          this.$router.push({
            name: "CRTShow",
            params: {
              CRTId: res.data.data.data.crtPictureId,
              deviceId: res.data.data.data.deviceInfoId,
            },
          });
        } else {
          this.$message({
            type: "warning",
            message: "该设备尚未添加点位！",
          });
        }
      });
    },

    // 点击复制设备ID
    copyInfo() {
      var clipboard = new Clipboard(".tag-read");
      clipboard.on("success", (e) => {
        this.$message.success("复制成功！");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.$message.error("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog {
  background: rgba(0, 0, 0, 0.5) !important;
  background-image: url(../../assets/images/panel.png) !important;
  background-size: 100% 100% !important;
  width: 50vw;
  height: 55vh;
  border-radius: 15px;
  padding: 10px 0 0 10px;
  text-shadow: 4px 4px 4px rgb(0, 0, 0) !important;

  position: relative;
}

/deep/.el-dialog__title {
  color: #81f8f2;
  font-size: 26px;
}
.container {
  display: flex;
  color: #81f8f2;
  font-size: 18px;
  text-shadow: none;
}
.left,
.right {
  width: 39%;
  height: 300px;
}
.zhuangshi {
  width: 180px;
  height: 15px;
  position: absolute;
  top: 70px;
}
.content {
  width: 100%;
  height: 40vh;
  cursor: pointer;
  padding: 20px 0;
  box-shadow: 0px 0px 20px #062b6c !important;

  .content-item {
    padding: 0px 0 5px 0;
    font-size: 22px;
    font-family: "宋体";
    span {
      width: 14.28%;
      display: inline-block;
      text-align: center;
      font-family: "宋体";
    }
  }

  .border-box {
    height: 90% !important;
    overflow: auto;

    .content-item1 {
      display: flex;
      .box-item {
        width: 14.28%;
        text-align: center;
        line-height: 50px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .content-item2 {
      width: 100px;
    }
  }
}
.content1 {
  width: 30%;
  // border: 1px solid red;
  padding: 0 0 0 10px;
}
.rt-top-top {
  padding: 10px 0 0 20px;
  line-height: 30px;
  display: inline-block;
  width: 70%;
}
.rt-top-btm {
  display: inline-block;
}

.check-details {
  background: rgba(14, 13, 13, 0.8);
}

/deep/.el-dialog__headerbtn .el-dialog__close {
  font-size: 25px;
  color: #fff;
  margin: 10px;
}
::-webkit-scrollbar {
  display: none;
}
.show-detils {
  display: flex;
  flex-wrap: wrap;
  height: 40vh;
  overflow-y: scroll;
  .detils {
    padding: 10px;
    width: 13vw;
    height: 15vh;
    margin: 25px 10px;
    line-height: 40px;
    padding-left: 20px;
    font-size: 18px;
    color: skyblue;
    animation: breathing 1s linear infinite alternate;

    div {
      display: block;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .box::before {
      content: "属性值：";
      color: rgb(176, 189, 187);
      font-size: 20px;
    }
  }
}
@keyframes breathing {
  0% {
    transform: scale(0.97);
  }
  100% {
    transform: scale(1);
  }
}
</style>