<template>
  <el-container class="home-container showbg">
     <!-- @wheel.prevent="bbimg" v-drag -->
    <div id="overburden">
          <!-- <el-popover
            placement="top"
            :title='AllFloorCRTList.name'
            width="300"
            trigger="click"
            v-for="item in indexPageCrtPointList"
            :id="item.crtPictureId"
            :style="{'left':(parseFloat(item.coordinatex)*showBgWid-parseFloat(item.iconWidth)/2).toString()+'px','top':(parseFloat(item.coordinatey)*showBgHei-parseFloat(item.iconWidth)/2).toString()+'px','width':item.iconWidth+'px','position':'absolute','z-index':'2','cursor':'pointer','z-index':'8'}"
            >
            <el-row :gutter="10" v-for="item in AllFloorCRTList.list" style="margin:5px 0">
              <el-col :span="13" style="line-height:28px;font-size:15px">{{item.name}}</el-col>
              <el-col :span="5" class="breath" :style="'line-height:28px;margin-right:5px;font-family:华文仿宋;'+('正常'==item.status?'color:#00ff00':'color:#ff0000')">{{item.status}}</el-col>
              <el-button type="primary" plain size="mini" style="float:right"  @click="toCRT(item.id)">查看</el-button>
            </el-row>
          <el-button slot="reference" class="iconButton" @click="requestCrtInfo(item.crtPictureId)"></el-button>
          </el-popover> -->

          <!-- <img v-for="item in indexPageCrtPointList" :id="item.crtPictureId" src="../assets/images/CRT点位.png" :style="{'left':(parseFloat(item.coordinatex)*showBgWid-parseFloat(item.iconWidth)/2).toString()+'px','top':(parseFloat(item.coordinatey)*showBgHei-parseFloat(item.iconWidth)/2).toString()+'px','width':item.iconWidth+'px','position':'absolute','z-index':'2','cursor':'pointer','z-index':'8'}"  @click="toCRT(item.crtPictureId)"></img> -->
      
          <img id="showBg" src="../assets/images/CSDSBJ.jpg" style="width:100%;height:100%"></img>

    </div>
    <el-header class="header-bg bgclor" style="height:80px;">
      <el-row>
        <!-- 模式切换 -->
        <span class="toggle">
          <i class="el-icon-s-unfold"  @click="toggle(5)"></i>
        </span>
        <el-col class="pl32 company" :span="8">
          {{ headerInfo.company }}
          <!-- 长沙世邦 -->
          <!-- <img src="../assets/images/cssb.png" alt=""> -->
          <!-- 爱欧科技 -->
          <!-- <img src="../assets/images/iotech.png" style="width:130px"> -->
        </el-col>
        <!-- title -->
        <el-col class="lh-tc mainTitle" :span="8">{{
          headerInfo.title
        }}</el-col>
        <!-- 时间 -->
        <el-col class="lh-12 time" :span="7">{{ headerInfo.time }}</el-col>
        <el-col :span="1">
          <div
            class="iconfont icon-tuichu"
            @click="exit"
          ></div
        ></el-col>
      </el-row>
    </el-header>

    <!-- 侧边框 -->
    <el-container  id="gg" class="spce-ard">
      <!-- 左边视图 -->
      <div class="bgframe">
        <!-- 左上 -->
        <div class="box">
          <div class="showtitilebg">
           <span class="icon"></span> 设备统计 </div>
          <div class="line"></div>
          <div class="top">
            <div class="top-msg" v-for="(item, index) in msgList" :key="item.ids" @click="bouncOfStat(item.ids)">
              <div>
                <span title="点击查看详细设备信息">{{ item.title }}:</span>
                <span>{{ item.num }}</span>
                <span>个</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 左下-->
        <div class="box " title="点击查看报警历史" @click="turnToHistory" >
          <div class="showtitilebg"><span class="icon"></span>报警记录</div>
          <div class="line"></div>
          <screen></screen>
        </div>
      </div>

      <!-- 右边视图 -->
      <div class="bgframe">
        <div class="box " >
          <div class="showtitilebg"><span class="icon"></span>报警数量</div>
          <!-- 直线 -->
          <div class="line"></div>
          <div title="点击查看详细设备信息">
            <div class="top">
              <div
                class="top-msg"
                style="padding: 0px 15px"
                v-for="(item, index) in alarmList"
                :key="index"
                 @click="bouncOfAlarm(index)"
              >
                <span style="height: 10px; padding-left:20px">{{ item.title }} </span>
                <span>{{ item.value }}</span>
                <span>个</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 右下 -->
        <div class="box "  >
          <div class="showtitilebg"><span class="icon"></span>报警占比</div>
          <div class="line"></div>

          
          <!-- 饼图 -->
          <pie></pie>
        </div>
      </div>
    </el-container>

    <!-- 设备统计 -->
    <wxyg :dialogFlag.sync="dialogFlag"></wxyg>
    <hzbj :dialogFlag.sync="dialogFlag1"></hzbj>
    <spsb :dialogFlag.sync="dialogFlag2"></spsb>
    <other-equip :dialogFlag.sync="dialogFlag3"></other-equip>

    <!-- 报警数量 -->
    <baojing :dialogFlag.sync="dialogFlag4"></baojing>
    <guzhang :dialogFlag.sync="dialogFlag5"></guzhang>
    <shilian :dialogFlag.sync="dialogFlag6"></shilian>

    <!-- 报警记录 -->
    <history :dialogFlag.sync="dialogFlag7"></history>


  </el-container>
</template>

<script>
import Shilian from "@/components/bounc/Shilian";
import Guzhang from "@/components/bounc/Guzhang";
import Baojing from "@/components/bounc/Baojing";
import OtherEquip from "@/components/bounc/OtherEquip";
import Spsb from "@/components/bounc/Spsb";
import Hzbj from "@/components/bounc/Hzbj";
import Wxyg from "@/components/bounc/Wxyg";
import rodt from "../components/echarts/ratioOfDeviceTypes.vue";
import newHistory from "@/components/bounc/latestAlarmRecord";
import HomePage from "@/api/manageApi/HomePage";

// import service from "../../utils/request.js";
import getTime from "../../utils/time.js";
import pie from "../components/echarts/pie.vue";
import screen from "@/components/echarts/ScreenScroll.vue";
import crtInfo from "@/api/manageApi/CrtInfo";
import deviceCachValue from "../api/manageApi/DeviceCachValue";
import indexPageCrtPoint from "@/api/manageApi/IndexPageCrtPoint";
import drag from "@/../utils/drag.js";

import deviceInfo from "../api/manageApi/DeviceInfoManagement";

// 设备报警信息
import alarmRecord from "@/api/manageApi/DeviceHistoryStatus";

export default {
  name: "Show",
  props: [],
  components: {
    pie: pie,
    rodt: rodt,
    wxyg: Wxyg,
    hzbj: Hzbj,
    spsb: Spsb,
    otherEquip: OtherEquip,
    baojing: Baojing,
    guzhang: Guzhang,
    shilian: Shilian,
    history: newHistory,
    screen: screen,
  },

  data() {
    return {
      showDetails: true,

      dialogFlag: false,
      dialogFlag1: false,
      dialogFlag2: false,
      dialogFlag3: false,
      dialogFlag4: false,
      dialogFlag5: false,
      dialogFlag6: false,
      dialogFlag7: false,

      showBgHei: 0,
      showBgWid: 0,
      //已添加点位列表
      indexPageCrtPointList: [],
      changeColor: false,

      alarmRecordList: [], // 报警记录

      bjDevice: [],

      AllFloorCRTLists: {},

      AllFloorCRTList: {
        name: "",
        list: [],
        id: "",
      },
      msgList: [
        {
          ids: 1,
          title: "无线烟感",
          num: "",
        },
        {
          ids: 2,
          title: "火灾报警",
          num: "",
        },
        {
          ids: 3,
          title: "视频设备",
          num: "",
        },
        {
          ids: 4,
          title: "其他设备",
          num: "",
        },
      ],
      alarmList: [],

      timer: null,
      headerInfo: {
        company: "城市大厦",
        // company: "江苏河马自动化设备有限公司",
        // company: "爱欧科技",
        title: "消防物联网集中监控平台",
        // title: "消防云平台管理系统",
        time: "yyyy-MM-dd HH:mm:ss",
      },
      alarmInfo: {},

      deviceHistoryStatus: {
        typeName: "",
        valueName: "",
      },
    };
  },

  created() {
    this.getDevicenumber();

    this.getFloorCRTdata2("0");
    // this.deviceRealValueInfo(); // 报警记录
    this.getIndexPageCrtPointList();
    // this.init();
    let timeObj = getTime.getTime();
    this.headerInfo.time = timeObj.dayTime();

    let ils = 0;
    this.timer = setInterval(() => {
      ils++;
      this.headerInfo.time = timeObj.dayTime();
      // if (ils % 5 == 0) this.getSmokeAboatInfo();
    }, 1000);
  },

  methods: {
    toggle() {
      this.$router.push("/ShowMap");
    },

    turnToHistory() {
      // this.$router.push("/HistoryValue");
      this.dialogFlag7 = true;
    },

    //获取已添加的点位信息
    getIndexPageCrtPointList() {
      indexPageCrtPoint.list().then((res) => {
        this.indexPageCrtPointList = res.data.data.list;
      });
    },
    handle() {
      this.changeColor = !this.changeColor;
    },
    dataHandlerIndex() {
      this.changeColor = true;
    },

    getDevicenumber() {
      deviceCachValue.getDevicenumber().then((res) => {
        this.msgList[0].num = res.data.data.YGNumber;
        this.msgList[1].num = res.data.data.HZNumber;
        this.msgList[2].num = res.data.data.SPNumber;
        this.msgList[3].num = res.data.data.QTNumber;
        this.alarmList = [
          {
            title: "报警 ：",
            value: res.data.data.baojin,
          },
          {
            title: "故障 ：",
            value: res.data.data.guzhang,
          },
          {
            title: "失联 ：",
            value: res.data.data.shilian,
          },
        ];
        this.alarmList;
      });
    },

    //退出展示页
    exit() {
      this.$router.push("/Select");
    },
    dataHandlerIndex(msg) {
      this.bjDevice.push(this.AllFloorCRTLists[msg.CRTId]);
      document
        .getElementById(this.AllFloorCRTLists[msg.CRTId])
        .classList.add("heart_red");

      this.alarmInfo["" + this.AllFloorCRTLists[msg.CRTId]] = msg.deviceId;
    },
    /************************图片操作************************/
    //缩放
    //图片缩放
    // bbimg() {
    //   var zoom =
    //     parseInt(document.getElementById("overburden").style.zoom, 10) || 100;
    //   zoom += event.wheelDelta / 12;
    //   if (zoom >= 50)
    //     document.getElementById("overburden").style.zoom = zoom + "%";
    //   return false;
    // },

    removeBjDevice(id) {
      for (let i = 0; i < this.bjDevice.length; i++) {
        if (this.bjDevice[i] == id) {
          delete this.bjDevice[i];
          break;
        }
      }
    },

    getBoolenBjDevice(id) {
      for (let i = 0; i < this.bjDevice.length; i++) {
        if (this.bjDevice[i] == id) {
          return true;
        }
      }
      return false;
    },

    /************************点位操作************************/
    //跳转CRT界面
    toCRT(crtPictureId) {
      this.removeBjDevice(crtPictureId);
      // alert(crtPictureId)

      // this.$alert('<div>CRT信息-ID:</div>' + crtPictureId  , 'CRT信息', {
      //     dangerouslyUseHTMLString: true,     // 处理 HTML 片段
      //     closeOnPressEscape: true,     // 通过 Esc 关闭消息框
      //     distinguishCancelAndClose: false
      //   });

      let deviceIdTemp = this.alarmInfo["" + crtPictureId];
      this.alarmInfo["" + crtPictureId] = "";
      document.getElementById(crtPictureId).classList.remove("heart_red");
      this.$router.push({
        name: "CRTShow",
        params: {
          CRTId: crtPictureId,
          deviceId: deviceIdTemp,
        },
      });
      // this.$router.replace('/CRTShow')
    },

    requestCrtInfo(crtPictureId) {
      this.getFloorCRTdata(crtPictureId);
    },
    // 获取楼层CRT数据
    getFloorCRTdata(crtPictureId) {
      crtInfo.getAllFloorCrt(crtPictureId).then((res) => {
        if (res.data.data.list.length == 0) {
          this.AllFloorCRTList.name = "没有点位";
          this.AllFloorCRTList.list = [];
          this.AllFloorCRTList.id = "";
        } else {
          this.AllFloorCRTList = res.data.data.list[0];
        }
        for (let i = 0; i < this.AllFloorCRTList.list.length; i++) {
          if (this.getBoolenBjDevice(this.AllFloorCRTList.list[i].id)) {
            this.AllFloorCRTList.list[i].status = "异常";
          }
        }
      });
    },
    // 获取CRT数据
    getFloorCRTdata2(crtPictureId) {
      crtInfo.getAllFloorCrt(crtPictureId).then((res) => {
        this.AllFloorCRTLists = {};
        for (let i = 0; i < res.data.data.list.length; i++) {
          for (let j = 0; j < res.data.data.list[i].list.length; j++) {
            this.AllFloorCRTLists["" + res.data.data.list[i].list[j].id] =
              res.data.data.list[i].crtId;
          }
        }
      });
    },

    // *******************报警记录********************
    // deviceRealValueInfo() {
    //   HomePage.getAllHistory().then((res) => {
    //     console.log(res);
    //     this.alarmRecordList = resp.data.data.list;
    //   });
    //   // deviceInfo.getDeviceInfo().then((res)=>{
    //   //   this.deviceInfoList = res.data.data.list;
    //   // })
    //   // alarmRecord.getAll(1, "未处理", "", "").then((resp) => {
    //   //   this.alarmRecordList = resp.data.data.list;
    //   // });
    // },

    bouncOfStat(ids) {
      if (ids == 1) {
        this.dialogFlag = true;
      } else if (ids == 2) {
        this.dialogFlag1 = true;
      } else if (ids == 3) {
        this.dialogFlag2 = true;
      } else if (ids == 4) {
        this.dialogFlag3 = true;
      }
    },

    bouncOfAlarm(index) {
      if (index == 0) {
        this.dialogFlag4 = true;
      } else if (index == 1) {
        this.dialogFlag5 = true;
      } else if (index == 2) {
        this.dialogFlag6 = true;
      }
    },
  },

  mounted() {
    this.bouncOfStat();

    this.$emit("routerIsIndexFunction", true);
    this.showBgHei = document.getElementById("gg").clientHeight;
    this.showBgWid = document.getElementById("gg").clientWidth;
    var _this = this;

    setTimeout(function () {
      var showBg = document.getElementById("showBg");
      _this.showBgWid = showBg.width;
      _this.showBgHei = showBg.height;

      _this.getIndexPageCrtPointList();
    }, 1000);
  },
  destroyed() {
    var that = this;
    that.$emit("routerIsIndexFunction", false);
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.iconfont {
  cursor: pointer;
  color: lightgreen;
  font-size: 26px;
  line-height: 80px;
  text-align: right;

  animation: breathing 1s linear infinite alternate;
}
@keyframes breathing {
  0% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(01);
  }
}
#overburden {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}
#showBg {
  width: 1920px;
  position: relative;

  overflow-y: hidden;
}
.company {
  color: aqua;
  font-size: 25px !important;
  line-height: 80px;
  letter-spacing: 6px;
  text-shadow: 4px 4px 3px #000;
}
.company img {
  margin: 22px 0 0 50px;
  width: 280px;
  mix-blend-mode: screen; // 滤色
}

.mainTitle {
  color: rgb(7, 228, 228);
  font-weight: 700 !important;
  font-size: 2.5vw !important;
  letter-spacing: 5px;
  font-family: "宋体";
  text-shadow: 5px 5px 3px #000;
}
.time {
  color: aqua;
  font-size: 1.5vw !important;
  line-height: 80px;
  text-shadow: 4px 4px 3px #000;
}
.spce-ard {
  justify-content: space-between;
}
.bgframe {
  width: 26%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 91vh;
  justify-content: space-between;
  padding-top: 10px;
}
.box {
  background: url("~@/assets/images/panel.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 30%;
  background-color: rgba(19, 29, 29, 0.4);
  margin: 0 auto;
  z-index: 0;
  border-radius: 30px;

  overflow: hidden;
  padding-bottom: px;
}

.showtitilebg {
  height: 50px;
  font-size: 22px;
  padding: 18px 0 0 17%;
  font-weight: 700;
  color: rgb(129, 248, 242);
  text-shadow: 1px 5px 3px #000;
  // text-align: center;
  letter-spacing: 5px;
  span {
    vertical-align: middle;
    display: inline-block;
    margin-right: 10px;
    background: url("~@/assets/images/icon01.png") no-repeat;
    width: 30px;
    height: 25px;
  }
}

.line {
  height: 5px;

  background: #2ed7cf;
  width: 83%;
  margin-left: 50px;
  margin-top: 10px;
  box-shadow: 2px 3px #000;
}
.top {
  height: calc(100% - 60px);
  padding: 10px 10px;
}

.top-msg {
  padding: 0 30px;
  margin-top: 1%;
  cursor: pointer;
}

.top-msg span {
  line-height: 25px;
  font-size: 1vw;
  color: aquamarine;
  text-shadow: 2px 2px 2px #000;
  letter-spacing: 2px;
  margin: 1px 0;
}

.top-msg span:nth-child(1) {
  display: inline-block;
  width: 55%;
  // height: 32px;
}
.top-msg span:nth-child(2) {
  display: inline-block;
  width: 18%;
  height: 30px;
  text-align: center;
  border: solid 1px #5decf1;
  color: #fff;
  margin-left: 20px;
  border-radius: 5px;

  animation: breathing 1s linear infinite alternate;
}
@keyframes breathing {
  0% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(01);
  }
}

.top-msg span:nth-child(3) {
  margin-left: 30px;
}

.right-top-msg span {
  height: 35px;
  line-height: 35px;
  font-size: 20px;
  color: aquamarine;
}
.bottom-shadow {
  box-shadow: -2px -2px 4px #000;
  margin-bottom: 10px;
}
.home-container {
  height: 100vh;
}

/deep/ .el-table tbody tr {
  pointer-events: none;
}
.el-header {
  padding: 0 10px;
  line-height: 60px;
  cursor: default;
  color: #fff;
}
.pl32 {
  text-align: center;
  font-size: 15px;
}

.lh-tc {
  font-weight: 500;
  line-height: 80px;
  text-align: center;
}

/deep/ .lh-12 {
  line-height: 80px;
  text-align: right;
  width: 25%;
}

.el-col-2 {
  text-align: right;
}

.el-main {
  z-index: 500;
}

// 图片闪动
@keyframes fade {
  from {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.btnsBox {
  width: 100px;
  height: 100px;
  background: red;
  position: absolute;
  left: 600px;
  top: 100px;
  border-radius: 50%;
  animation: fade 600ms infinite;
}
.changeColor {
  background: green;
  animation: fade 600ms infinite;
  animation: fade 600ms infinite;
}

.equip_real_value {
  width: 86%;
  margin: 0 auto;
}
.equip_real_value span {
  width: 33.33%;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  font-size: 18px;
  color: #43f0d4;
  font-weight: 500;
}
.equip_real_content {
  width: 86%;
  margin: 0 auto;
  display: flex;
}
.equip_real_content .equipInfo {
  width: 33.33%;
}
.equip_real_content .equipInfo div {
  line-height: 32px;
}
.check:hover {
  color: blue;
}
.iconButton {
  background: url(../assets/images/CRT点位.png) no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  border: none;
  outline: none;
  cursor: pointer;
}
.deviceAddress {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: nowrap;
}
.breath {
  font-size: 16px;
  animation: breathing1 1s linear infinite alternate;
}
@keyframes breathing1 {
  0% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(01);
  }
}
.toggle {
  position: absolute;
  top: 10px;
  left: 20px;
  color: #b5edf5;
  box-sizing: border-box;
  font-size: 28px;
}
</style>
