<template>
  <div id="app">
    <el-dialog
      title="报警记录"
      :visible.sync="visible"
      :close-on-click-modal="false"
      @close="dialogClose"
    >
      <dv-decoration-6 class="zhuangshi" />

      <el-button type="primary" class="seeHistory" @click="toHistory"
        >查看历史</el-button
      >

      <div class="container">
        <div style="padding-top: 10px; cursor: pointer">
          <span>设备号</span>
          <span>设备类型</span>
          <span>报警类型</span>
          <span>处理状态</span>
          <span>创建时间</span>
          <dv-decoration-10 style="width: 100%; height: 5px" />
        </div>
        <div class="content" style="cursor: pointer">
          <div v-for="(item, index) in lists" :key="item.index">
            <span class="item-1" :title="item.id">{{ item.deviceId }}</span>
            <span class="item-1" :title="item.type">{{ item.typeName }}</span>
            <span class="item-1" :title="item.attribute">{{ item.value }}</span>
            <span class="item-1" :title="item.value">{{
              item.handleStatus
            }}</span>
            <span class="item-1" :title="item.time">{{ item.createTime }}</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import HomePage from "@/api/manageApi/HomePage";

export default {
  name: "wxyg",
  props: {
    dialogFlag: {
      default: false,
    },
  },
  data() {
    return {
      lists: [],
      visible: false,
    };
  },
  created() {
    this.getAllHistory()

    // setInterval(() => {
    //     ///调取接口
    // }, 10000);
  },
  watch: {
    dialogFlag() {
      this.visible = this.dialogFlag;
    },
  },
  methods: {
    getAllHistory(){
      HomePage.getAllHistory().then((res) => {
                if (res.data.data.list != null) {
                    this.lists = res.data.data.list
                }

            });
    },
    dialogClose() {
      this.$emit("update:dialogFlag", false);
    },

    // 查看视频按钮
    toHistory(){
      this.$router.push("/History");

    }
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog {
  background: rgba(0, 0, 0, 0.5) !important;
  background-image: url(../../assets/images/panel.png) !important;
  background-size: 100% 100% !important;
  width: 50vw;
  height: 55vh;
  border-radius: 15px;
  padding: 10px 0 0 10px;
  text-shadow: 4px 4px 4px rgb(0, 0, 0) !important;

  position: relative;
  .closes {
    position: absolute;
    right: 10px;
    bottom: 15px;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    outline: none;
    font-size: 16px;
    padding: 5px 10px;
    color: rgb(240, 238, 238);
    background: rgba(26, 25, 25, 0.8);
    box-shadow: 2px 2px 2px #000;
  }
}
/deep/.el-dialog__headerbtn .el-dialog__close {
  font-size: 25px;
  color: #fff;
  margin: 10px;
}
/deep/.el-dialog__title {
  color: #81f8f2;
  font-size: 26px;
}
.container {
  width: 100%;

  span {
    width: 17%;
    display: inline-block;
    padding: 10px;
    text-align: center;
    color: #81f8f2;
    font-size: 18px;
  }
  .content {
    height: 340px;
    overflow: auto;
  }
}
.zhuangshi {
  width: 180px;
  height: 15px;
  position: absolute;
  top: 70px;
}
::-webkit-scrollbar {
  display: none;
}
.seeHistory {
  position: absolute;
  top: 10%;
  left: 25%;
  font-size: 16px;
}
.item-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>