<template>
  <div id="app">
    <el-dialog
      title="视频设备"
      :visible.sync="visible"
      :close-on-click-modal="false"
      @close="dialogClose"
    >
      <dv-decoration-6 class="zhuangshi" />

      <div class="container">
        <div style="padding-top: 10px; cursor: pointer">
          <span>序号</span>
          <span>设备ID</span>
          <span>设备类型</span>
          <span>安装位置</span>
          <span>操作</span>
          <dv-decoration-10 style="width: 100%; height: 5px" />
        </div>
        <div class="content" style="cursor: pointer">
          <div v-for="(item, index) in itemList" :key="item.index">
            <span class="video-item">{{ index + 1 }}</span>
            <span class="video-item" :title="item.id">{{ item.id }}</span>
            <span class="video-item" :title="item.type">{{ item.type }}</span>
            <span class="video-item" :title="item.address">{{
              item.address
            }}</span>
            <span>
              <el-button type="primary" size="small" @click="viewVideo(item.id)"
                >查看视频</el-button
              >
            </span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import HomePage from "@/api/manageApi/HomePage";

import deviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";
// import deviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";

export default {
  name: "Spsb",
  props: {
    dialogFlag: {
      default: false,
    },
  },
  data() {
    return {
      itemList: [],
      visible: false,
      deviceName: "摄像头",
      videoUrl: "",
    };
  },
  created() {
    this.getWxygInfo();
  },
  watch: {
    dialogFlag() {
      this.visible = this.dialogFlag;
    },
  },
  methods: {
    dialogClose() {
      this.$emit("update:dialogFlag", false);
    },

    getWxygInfo() {
      HomePage.wirelessSmokeDetector(this.deviceName).then((res) => {
        this.itemList = res.data.data.itemList;
      });
    },
    // 查看视频按钮
    viewVideo(id) {
      HomePage.viewVideo(id).then((res) => {
        this.videoUrl = res.data.data.videoUrl;
        window.location.href = this.videoUrl;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog {
  background: rgba(0, 0, 0, 0.5) !important;
  background-image: url(../../assets/images/panel.png) !important;
  background-size: 100% 100% !important;
  width: 50vw;
  height: 55vh;
  border-radius: 15px;
  padding: 10px 0 0 10px;
  text-shadow: 4px 4px 4px rgb(0, 0, 0) !important;

  position: relative;

  .closes {
    position: absolute;
    right: 10px;
    bottom: 15px;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    outline: none;
    font-size: 16px;
    padding: 5px 10px;
    color: rgb(240, 238, 238);
    background: rgba(26, 25, 25, 0.8);
    box-shadow: 2px 2px 2px #000;
  }
}

/deep/.el-dialog__headerbtn .el-dialog__close {
  font-size: 25px;
  color: #fff;
  margin: 10px;
}

/deep/.el-dialog__title {
  color: #81f8f2;
  font-size: 26px;
}

.container {
  width: 100%;
  span {
    width: 17%;
    display: inline-block;
    padding: 10px;
    text-align: center;
    color: #81f8f2;
    font-size: 18px;
  }

  .content {
    height: 340px;
    overflow: auto;
  }
}
::-webkit-scrollbar {
  display: none;
}
.zhuangshi {
  width: 180px;
  height: 15px;
  position: absolute;
  top: 70px;
}
.video-item {
  // display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
